
import { defineComponent } from 'vue';
import store from '@/store';
import UiCombobox from '@/components/form/UiCombobox.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import UiOverlay from '@/components/form/UiOverlay.vue';
import UiButton from '@/components/clickables/UiButton.vue';
import Clickable from '@/components/clickables/Clickable.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import AuthService from '@/services/AuthService';
import AccountingService from '@/services/AccountingService';
import {
  User,
  UserSearchParams,
  ApiError,
} from '@/models/auth';
import {
  Company,
  CompanySearchParams,
} from '@/models/accounting';
import RolePermissions from '@/mixins/RolePermissions';

export default defineComponent({
  name: 'ManangeUsers',
  mixins: [RolePermissions],
  components: {
    UiCombobox,
    UiSearch,
    Pagination,
    Clickable,
    UiOverlay,
    UiButton,
    PreLoader,
  },
  data: () => ({
    size: 1 as number,
    total: 0 as number,
    currentPage: 1 as number,
    usersList: [] as User[],
    isLoaded: false as boolean,
    isEmpty: false,
    isPageLoaded: false,
    userName: undefined as string | undefined,
    companyId: undefined as string | undefined,
    checked: false,
    companies: [] as Company[],
    selectedCompany: '',
    companySearchInput: '',
    searchTimeoutId: 0,
    onDeleteCompanyId: '',
    confirmationModal: false,
  }),
  async created() {
    const companyCode = process.env.VUE_APP_PRIORITIZE_COMPANY_CODE as string;

    if (companyCode) {
      try {
        const prioritizedCompany: Company = await AccountingService.showCompany(companyCode);

        this.addCompanies(prioritizedCompany);
      } catch (error) {
        this.isPageLoaded = false;
      }
    }

    const params: CompanySearchParams = {
      take: 10,
    };

    const companies = await AccountingService.listCompanies(params) as any;

    if (companies.data.length) {
      companies.data.forEach((company: Company) => this.addCompanies(company));
    }

    this.fetchUsers();
  },
  watch: {
    async userName(val) {
      if (!/\S/.test(val) && val !== '') {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        this.isEmpty = false;
        this.currentPage = 1;
        await this.fetchUsers();
      }, 300);
    },
    async companySearchInput(val) {
      if (!/\S/.test(val) && val !== '') {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params: CompanySearchParams = {
          page: 1,
          take: 10,
        };

        if (val !== '') {
          params.search_name = val;
        }

        const companies = await AccountingService.listCompanies(params) as any;

        this.companies = companies.data;
      }, 300);
    },
    async selectedCompany(val: Company) {
      this.companyId = val.id ? val.id : '';
      this.isEmpty = false;
      this.currentPage = 1;
      this.fetchUsers();
    },
  },
  computed: {
    currentItems(): number {
      const currentTotal = 10 * this.currentPage;
      return currentTotal >= this.total ? this.total : currentTotal;
    },
    currentUserId() {
      return store.getters?.user?.id;
    },
  },
  methods: {
    canEdit(roles: any) {
      const isSuperAdmin = roles.find((role: any) => role.name === 'super-admin');

      if (isSuperAdmin) {
        if (this.hasRole('super-admin')) {
          return true;
        }

        return false;
      }

      return true;
    },
    addCompanies(company: Company): void {
      if (company) {
        const isExists = this.companies.filter((filCom) => company.id === filCom.id).length;
        if (!isExists) this.companies.push(company);
      }
    },
    userCompanies(user: User): string {
      if (user.companies.length) {
        return user.companies.map((company) => company.name).join(', ');
      }

      return '---';
    },
    userRoles(user: User): string {
      if (user.roles.length) {
        return user.roles.map((role) => role.name).join(', ');
      }

      return '---';
    },
    scrollBack(): void {
      const source = this.$refs['user-list-anchor'] as HTMLElement;
      const top = source.offsetTop - 10;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    },
    async fetchUsers(): Promise<void> {
      this.isLoaded = false;
      this.usersList = [];
      this.total = 0;

      const params: UserSearchParams = {
        page: this.currentPage,
        search: this.userName,
        company_code: this.companyId,
        take: 10,
      };

      const service = await AuthService.listUsers(params);

      this.usersList = [];

      if (service.data.length) {
        this.usersList = service.data;
        this.size = Math.ceil(service.total / service.per_page);
        this.total = service.total;
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }

      this.isLoaded = true;
      this.isPageLoaded = true;
    },
    onDeleteHandler(id: string): void {
      this.onDeleteCompanyId = id;
      this.confirmationModal = true;
    },
    async onDeleteConfirmed(): Promise<void> {
      this.isLoaded = false;
      const response = await AuthService.deleteUser(this.onDeleteCompanyId);

      const apierror: ApiError = response as ApiError;

      if (apierror?.message) {
        this.$toast.show(`Error: ${apierror.message}`, 3000);
      } else {
        this.$toast.show('User deleted succefully', 3000);
        this.confirmationModal = false;
        this.onDeleteCompanyId = '';
        this.fetchUsers();
      }
    },
    paginationHandler(): void {
      this.fetchUsers();
    },
  },
});
